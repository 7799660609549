import React from 'react';
import { useImmer } from 'use-immer';
import _ from 'lodash';
import { Button, Row, Col } from 'shards-react';
import { useMediaQuery } from 'react-responsive';
import MetaTags from 'react-meta-tags';

import images from '../../images/images';
import { TopScrollContainer, RoomPopUp } from '../../components/custom-list/index';
import useRouter from '../../hooks/use-router';

const TainanHall = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 992px)' });
  const [state, produce] = useImmer({
    ...location.state,
    tabSelect: 0,
    detail: null,
  });
  const { tabSelect, detail } = state;
  const { push } = useRouter();
  const TainanPublicDatas = [
    {
      image: images.tainan_06_a_01,
      title: '大寶友善遊戲區',
      items: ['打造溫馨多彩的遊戲空間，提供各式感覺統合玩具與豐富童書繪本，大寶開心、一同迎接家中新成員、創造全家人的歡樂回憶。'],
    },
    {
      image: isMobile ? images.tainan_06_a_02 : images.tainan_06_a_03,
      image2: isMobile ? images.tainan_06_a_03 : images.tainan_06_a_02,
      title: '嬰兒室',
      items: [
        '雙獨立隔離、觀察室，感控升級。',
        '防火玻璃、防火門、無毒抗菌地板，高規格守護寶寶。',
        '寶寶專屬攝影鏡頭，隨時關注寶寶的即時動態。',
        '每週3次紫外線消毒，維護環境清潔與安全。',
      ],
    },
    {
      image: images.tainan_06_a_04,
      title: '媽媽幸福空間',
      items: [
        'SPA、Hair Salon、泌乳：享受舒心SPA、美髮專屬服務。',
        '媽媽教室：量身訂做多元主題課程，從孕期到產後幫助爸媽成為親子大師。',
      ],
    },
    {
      image: images.tainan_06_a_12,
      image2: images.tainan_06_a_13,
      title: '陪宿專屬遊憩空間',
      items: [
        '獨立沙發與桌椅，讓爸爸自在休憩的充電處所。',
        '溫馨規劃大寶友善空間，提供各式玩具和繪本，讓爸媽及大寶共享親子歡樂時光。',
      ],
    },
    {
      image: images.tainan_06_a_11,
      title: '多功能諮詢室',
      items: [
        '資深護理人員，協助解決各種母嬰照顧、媽咪泌乳諮詢…等疑問，面對緊急狀況快速提供應變措施，成為媽咪們堅強的後盾。',
        '身體組成測量，科學化數據評估產後媽咪生理狀況，搭配駐館營養師一對一諮詢，給予營養建議。'
      ],
    },
    {
      // image: images.tainan_06_a_08,
      // title: '陽光交誼廳',
      // items: ['設有大片落地窗，遼闊的窗外景色讓媽咪度過午後悠閒時光。'],
      image: images.tainan_06_a_09,
      title: '交誼廳',
      items: [
        '媽咪們自拍、聊天、交朋友、閱讀報章雜誌，享受愜意時光的好地方。'
      ],
    },
    {
      image: images.tainan_06_a_10,
      title: '接待大廳',
      items: ['嚴格執行感染管控、門禁管制，守護媽咪、寶寶安全。',
      '全館採用高感控安全設置，即時監控空氣品質。',
      '配有高效能空氣清淨機，維持會館清新空氣。',],
    },
  ];
  const TainanRoomDatas = [
    {
      image: images.tainan_06_b_01,
      title: '寬敞大器 沐辰房',
      plain: '24 坪',
      content: '一房一廳、1.5套衛浴設備、專屬更衣室，打造客廳、睡房、衛浴與梳化的私人空間。',
      link: 'https://www.google.com/local/place/fid/0x346e767eba73429d:0xc212642c220a95f5/photosphere?iu=https://lh5.googleusercontent.com/p/AF1QipOinf1Nh9U-nnBwpSIc1Y32ZajLF2qB-Uxad86Q%3Dw160-h106-k-no-pi-10-ya2.7973998-ro-0-fo100&ik=CAoSLEFGMVFpcE9pbmYxTmg5VS1ubkJ3cFNJYzFZMzJaYWpMRjJxQi1VeGFkODZR',
      imgArr: [
        images.tainan_room_01_01,
        images.tainan_room_01_02,
        images.tainan_room_01_03,
        images.tainan_room_01_04,
        images.tainan_room_01_05,
        images.tainan_room_01_06,
        images.tainan_room_01_07,
        images.tainan_room_01_08,
        images.tainan_room_01_09,
      ],
      itemArr: ['電動床','tokuyo 按摩椅','TOTO浴室設備', '暖風機', '玄關電動隱私捲簾', '賀眾牌飲水機', 'Panasonic雙層冰箱'],
    },
    {
      image: images.tainan_06_b_02,
      title: '華麗優雅 水月房',
      plain: '14-15 坪',
      content: '簡約溫暖色調，飽含喜悅、舒適雅致。',
      link: 'https://www.google.com/local/place/fid/0x346e767eba73429d:0xc212642c220a95f5/photosphere?iu=https://lh5.googleusercontent.com/p/AF1QipMr5SS6W23jnbdLmkKDxHypBwHDbxqF94Z1PSrC%3Dw160-h106-k-no-pi-10-ya12.042704-ro-0-fo100&ik=CAoSLEFGMVFpcE1yNVNTNlcyM2puYmRMbWtLRHhIeXBCd0hEYnhxRjk0WjFQU3JD',
      imgArr: [
        images.tainan_room_02_01,
        images.tainan_room_02_02,
        images.tainan_room_02_03,
        images.tainan_room_02_04,
        images.tainan_room_02_05,
      ],
      itemArr: ['tokuyo 按摩椅', 'TOTO浴室設備', '暖風機', '玄關電動隱私捲簾', '賀眾牌飲水機', 'Panasonic雙層冰箱'],
    },
    {
      image: images.tainan_06_b_03,
      title: '閑靜優雅 水月房B',
      plain: '14-15 坪',
      content: '簡約溫暖色調，自然溫馨。',
      link: 'https://www.google.com/local/place/fid/0x346e767eba73429d:0xc212642c220a95f5/photosphere?iu=https://lh5.googleusercontent.com/p/AF1QipM9avkgIwBmEfj1TamOydUPFABkt73d9BdtMuLD%3Dw160-h106-k-no-pi0-ya333.2042-ro-0-fo100&ik=CAoSLEFGMVFpcE05YXZrZ0l3Qm1FZmoxVGFtT3lkVVBGQUJrdDczZDlCZHRNdUxE',
      imgArr: [
        images.tainan_room_03_01, 
        images.tainan_room_03_02, 
        images.tainan_room_03_03, 
        images.tainan_room_03_04, 
        images.tainan_room_03_05,
      ],
      itemArr: [
        { text: 'TOTO浴室設備' }, 
        { text:'暖風機' }, 
        { text:'玄關電動隱私捲簾' }, 
        { text:'賀眾牌飲水機' }, 
        { text:'Panasonic雙層冰箱' }, 
        { text:'逗寶帳篷床非標配採限量提供預約請早', hightLight: true }
      ],
    },
  ];
  const TainanConnectionDatas = [
    {
      title: `電${'\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0'}話`,
      content: '(06) 215 0599',
    },
    {
      title: `地${'\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0'}址`,
      content: '台南市中西區永福路一段185號3樓',
    },
    {
      title: '停車資訊',
      content: 'CITY PARKING  B2 為國泰台南產後護理之家特約停車場，入住期間可享停車優惠。',
    },
  ];

  const CustomImg = ({ width, imgSrc, style }) => (
    <img
      loading="lazy"
      src={imgSrc}
      style={{
        marginBottom: isMobile && 15,
        width: isMobile ? 'calc(100% - 20px)' : width,
        marginLeft: isMobile ? 25 : 15,
        height: 'fit-content',
        objectFit: isMobile ? 'cover' : '',
        borderRadius: 6,
        ...style,
      }}
    />
  );

  const CustomSpan = ({ title, content, key }) => (
    <div style={{ display: 'flex' }} key={key}>
      <span style={{ fontSize: 16, whiteSpace: 'nowrap', color: '#5E5E5E', fontWeight: 400 }}>{title}</span>
      <span style={{ marginLeft: 10, marginBottom: 15, fontSize: 16, color: '#5E5E5E', fontWeight: 400 }}>{content}</span>
    </div>
  );

  return (
    <TopScrollContainer
      breadcrumb={[{ title: '首頁', to: '/' }, { title: '台南' }]}
      titleBackground={images.top_05_a}
      background={images.background_06_a}
      title={isMobile ? '台南' : '國泰台南產後護理之家'}
      titleTab={['公共區域', '房型介紹', '聯絡方式']}
      // titleBtn={tabSelect == 0 ? ['遊戲區', '嬰兒室', '媽媽空間', '接待大廳', '交誼廳'] : null}
      handleChange={(index) => produce((draft) => void (draft.tabSelect = index))}
      // isShowBtnMobile={true}
      // isShowLineMobile={true}
    >
      <MetaTags>
        <title>台南會館房型與服務介紹｜台南市產後護理之家推薦｜國泰產後護理之家</title>
        <meta
          name="description"
          content="國泰台南會館產後護理之家，位於台南市中西區，專業醫護陣容定期巡診，24小時全護理師照護，專屬營養師協助體態恢復，坐月子明星規格尊榮待遇，立即預約！"
        />
        <meta
          name="keywords"
          content="產後護理之家,產後護理,國泰台南產後護理之家,國泰產後護理之家台南會館,台南 產後護理之家,中西區 月子中心,台南 月子中心,產後護理之家 推薦,月子中心 推薦"
        />
      </MetaTags>
      {tabSelect == 0 && (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {_.map(TainanPublicDatas, (data, index) => (
              <div key={index}>
                {index == 0 && !isMobile && (
                  <div style={{ textAlign: 'center' }}>
                    <span style={{ marginRight: 15 }}>嚴格把關 用心守護</span>
                    <span style={{ fontWeight: 400 }}>定期檢驗館內空氣品質，守護媽咪、寶寶健康</span>
                  </div>
                )}
                {/* 2022-11-24 隱藏大寶友善 */}
                {index === 0 ? (
                  <></>
                ) : 
                (
                  <Row
                    id={`item${index}`}
                    className={index == 0 && isMobile ? '' : isMobile ? 'mt-4' : 'mt-4 mb-4'}
                    style={{
                      width: '100%',
                      flex: 1,
                      flexDirection:
                        index < 3 ? (index % 2 == 1 ? 'row-reverse' : 'row') : index % 2 == 1 ? 'row' : 'row-reverse',
                    }}
                  >
                    {
                      <img
                        loading="lazy"
                        src={data.image}
                        style={{
                          width: (index == 1 || index == 3) ? (isMobile ? '100%' : '30%') : isMobile ? 'calc(100% - 20px)' : '65%',
                          height: isMobile ? 260 : 'fit-content',
                          marginLeft: isMobile ? 25 : 15,
                          objectFit: isMobile ? 'cover' : '',
                          borderRadius: 6,
                          marginBottom: isMobile ? 20 : '',
                        }}
                      />
                    }
                    { (index == 1 || index == 3) && (
                      <img
                        loading="lazy"
                        src={data?.image2}
                        style={{
                          width: isMobile ? '100%' : '30%',
                          height: 'fit-content',
                          marginLeft: isMobile ? 25 : 15,
                          marginBottom: isMobile ? 20 : '',
                        }}
                      />
                    )}
                    <Col className="ml-2">
                      <h4>{data.title}</h4>
                      <ul style={{ marginLeft: -15 }}>
                        {_.map(data.items, (item, index) => (
                          <li
                            key={index}
                            style={{
                              fontSize: 16,
                              color: '#5E5E5E',
                              textAlign: 'justify',
                              fontWeight: 400,
                              marginBottom: 15,
                              listStyleImage: `url(${images.tree_icon})`,
                            }}
                          >
                            {item}
                          </li>
                        ))}
                      </ul>
                    </Col>
                  </Row>
                )}
                {index == 2 && (
                  <Row style={{ width: '100%', flex: 1 }}>
                    <img
                      loading="lazy"
                      src={images.tainan_06_a_05}
                      style={{
                        width: isMobile ? 'calc(100% - 20px)' : `${1000 / 31}%`,
                        paddingLeft: isMobile ? '' : 15,
                        marginLeft: isMobile ? 25 : '',
                        height: isMobile ? 260 : '',
                        objectFit: isMobile ? 'cover' : '',
                        borderRadius: 6,
                        marginBottom: isMobile ? 20 : '',
                      }}
                    />
                    <img
                      loading="lazy"
                      src={images.tainan_06_a_06}
                      style={{
                        width: isMobile ? 'calc(100% - 20px)' : `${2100 / 31}%`,
                        paddingLeft: isMobile ? '' : 30,
                        marginLeft: isMobile ? 25 : '',
                        height: isMobile ? 260 : 'fit-content',
                        objectFit: isMobile ? 'cover' : '',
                        borderRadius: 6,
                        marginBottom: isMobile ? 20 : '',
                      }}
                    />
                  </Row>
                )}

                {index != TainanPublicDatas.length - 1 && (
                  <Row
                    style={{
                      marginTop: isMobile ? '' : 19,
                      background: '#C5C1BA',
                      height: 1,
                      marginRight: 0,
                    }}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      )}
      {/* 房型介紹 */}
      {tabSelect == 1 && (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {_.map(TainanRoomDatas, (data, index) => (
              <div key={index}>
                <Row
                  id={`item${index}`}
                  className={index == 0 && isMobile ? '' : isMobile ? 'mt-4' : 'my-4'}
                  style={{ width: '100%', flex: 1 }}
                >
                  <CustomImg imgSrc={data.image} width={'65%'} />
                  <Col className="ml-2" style={{ alignSelf: 'center' }}>
                    <h4>{data.title}</h4>

                    <div style={{ display: 'flex' }}>
                      <img className="mr-1 lazy" src={images.square_feet} height={22} width={22} />
                      <span style={{ display: 'flex', marginBottom: 10 }}>{data?.plain}</span>
                    </div>

                    <span
                      style={{
                        fontSize: 16,
                        color: '#5E5E5E',
                        textAlign: 'justify',
                        fontWeight: 400,
                      }}
                    >
                      {data?.content}
                    </span>

                    <div style={{ display: 'flex', marginTop: 15, width: '100%', justifyContent: isMobile ? 'center' : '' }}>
                      <Button
                        theme="success"
                        className="my-1"
                        style={{
                          maxHeight: 42,
                          width: 100,
                          fontSize: 16,
                          marginRight: 15,
                          backgroundColor: 'rgba(82,181,79,1)',
                        }}
                        onClick={() => produce((draft) => void (draft.detail = data))}
                      >
                        查看詳細
                      </Button>
                     {data?.link && (
                        <Button
                          theme="success"
                          className="my-1"
                          style={{
                            maxHeight: 42,
                            width: 100,
                            fontSize: 16,
                            backgroundColor: 'transparent',
                            color: 'rgba(82,181,79,1)',
                          }}
                          onClick={() => window.open(data?.link, '_blank')}
                        >
                          環景預覽
                        </Button>
                      )} 
                    </div>
                  </Col>
                </Row>

                {index != TainanPublicDatas.length - 1 && (
                  <Row
                    style={{
                      marginTop: 19,
                      background: '#C5C1BA',
                      height: 1,
                      marginRight: 0,
                    }}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      )}
      {/* 聯絡方式 */}
      {tabSelect == 2 && (
        <div
          style={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : '',
            marginTop: 15,
            paddingLeft: isMobile ? 10 : '',
            paddingRight: isMobile ? 10 : '',
          }}
        >
          <div style={{ width: isMobile ? '100%' : '85%', height: isMobile ? 250 : '50vh', position: 'relative' }}>
            <iframe
              src="https://www.google.com/maps/embed/v1/place?key=AIzaSyASdP6rUVNZ5zvDVUJy86BP_EToxexL_Kc&q=%E5%9C%8B%E6%B3%B0%E5%8F%B0%E5%8D%97%E7%94%A2%E5%BE%8C%E8%AD%B7%E7%90%86%E4%B9%8B%E5%AE%B6&language=zh-tw"
              width="100%"
              height="100%"
              frameBorder="0"
              style={{ border: 0 }}
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
            />
          </div>
          <div style={{ marginLeft: isMobile ? '' : 30, marginTop: 20, width: isMobile ? '100%' : '40%' }}>
            <h2 style={{ fontSize: 24, color: '#5E5E5E', fontWeight: 500 }}>聯絡方式</h2>
            {_.map(TainanConnectionDatas, (item, index) => (
              <CustomSpan key={index} title={item.title} content={item.content} />
            ))}
          </div>
        </div>
      )}
      {detail && (
        <RoomPopUp
          isShowDialog={!!detail}
          overlayAction={() => {
            produce((draft) => void (draft.detail = null));
            push('/tainan-hall');
          }}
          detail={detail}
          isShowRealView={false}
        />
      )}
    </TopScrollContainer>
  );
};

export default TainanHall;
