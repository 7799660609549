import React from 'react';
import { useImmer } from 'use-immer';
import _ from 'lodash';
import { Button, Row, Col } from 'shards-react';
import { useMediaQuery } from 'react-responsive';
import MetaTags from 'react-meta-tags';

import images from '../../images/images';
import { TopScrollContainer, RoomPopUp } from '../../components/custom-list/index';
import useRouter from '../../hooks/use-router';

const HeihuHall = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 992px)' });
  const [state, produce] = useImmer({
    ...location.state,
    tabSelect: 0,
    detail: null,
  });
  const { tabSelect, detail } = state;
  const { push } = useRouter();
  const NeihuPublicDatas = [
    {
      image: images.neihu_07_a_01,
      title: '大寶友善遊戲區',
      items: [
        '打造溫馨的大寶遊戲區，提供各式感覺統合玩具與豐富童書繪本，讓大寶開心。',
      ],
    },
    {
      image: isMobile ? images.neihu_07_a_02 : images.neihu_07_a_03,
      image2: isMobile ? images.neihu_07_a_03 : images.neihu_07_a_02,
      title: '嬰兒室',
      items: [
        '雙嬰兒室分區、雙獨立隔離/觀察室，感控升級。',
        '防火玻璃、防火門、無毒抗菌地板，高規格守護寶寶。',
        '每週2次紫外線消毒，維護環境清潔與安全。',
      ],
    },
    {
      image: images.neihu_07_a_04,
      title: '媽媽幸福空間',
      items: [
        '多功能室：定期舉辦多元化媽媽教室，由專業講師親自傳授孕期營養、產後護理秘笈，並在瑜珈老師/物理治療師的指導下伸展與舒緩。',
        '休憩區：寬敞窗戶設計，一覽遼闊山景，讓您享受靜謐時光。',
      ],
    },
    {
      image: images.neihu_07_a_07,
      title: '接待大廳',
      items: ['嚴格執行感染管控、門禁管制，守護媽咪、寶寶安全。', '配有高效能空氣清淨機，維持會館清新空氣。'],
    },
    {
      image: images.neihu_07_a_08,
      title: '衛教諮詢室',
      items: ['專業醫療團隊尊榮、客製化一對一諮詢服務，提供媽咪產前、產後護理、育嬰和營養知識。'],
    },
  ];
  const NeihuRoomDatas = [
    {
      image: images.neihu_07_b_01,
      title: '寬敞尊寵 泊樂房',
      plain: '15 坪',
      content: 'ㄧ房ㄧ廳，空間寬敞舒適，視野遼闊，延攬大面採光，盡享陽光景緻。',
      link: 'https://www.google.com/maps/@25.0780729,121.5755497,3a,75y,149.67h,69.62t/data=!3m6!1e1!3m4!1sAF1QipNcj8MLgCeqX7skdljD7QINo3IBTo72wVn7WqYC!2e10!7i11116!8i5558',
      imgArr: [images.neihu_room_01_01, images.neihu_room_01_02, images.neihu_room_01_03, images.neihu_room_01_04, images.neihu_room_01_05],
      itemArr: [
        'Queen size床',
        '隱私拉門',
        '浴室乾濕分離',
        '衛浴暖風機',
        'TOTO頂級衛浴設備(免治馬桶)',
        '按摩椅',
        '奶瓶消毒鍋',
        '雙門冰箱',
        '保險箱',
      ],
    },
    {
      image: images.neihu_07_b_02,
      title: '溫馨雅緻 和樂房',
      plain: '10 坪',
      content: '室內採光明亮，品味怡人，打造舒適放鬆的愜意環境。',
      link: 'https://www.google.com/maps/@25.0780583,121.575673,3a,75y,112.91h,75.15t/data=!3m6!1e1!3m4!1sAF1QipNWfEGTpKn3jQpKU_0HJEjFZpNNeIXtMU-h1TP8!2e10!7i11096!8i5548',      
      imgArr: [images.neihu_room_02_01, images.neihu_room_02_02, images.neihu_room_02_03, images.neihu_room_02_04, images.neihu_room_02_05],
      itemArr: [
        'Queen size床',
        '隱私拉門',
        '浴室乾濕分離',
        '衛浴暖風機',
        'TOTO頂級衛浴設備(免治馬桶)',
        '貴妃椅',
        '奶瓶消毒鍋',
        '雙門冰箱',
        '保險箱',
      ],
    },
    {
      image: images.neihu_07_b_03,
      title: '簡約典雅 和樂房B',
      plain: '9.5 坪',
      content: '精緻美學維度，營造療癒紓壓的產後護理環境，提供溫馨舒適休憩場所。',
      link: 'https://www.google.com/maps/@25.0781098,121.5755876,3a,75y,198.2h,78.6t/data=!3m6!1e1!3m4!1sAF1QipPt1m4VF7uayemDES2fvhGsEmplaNhsXLNTlddT!2e10!7i11160!8i5580',
      imgArr: [images.neihu_room_03_01, images.neihu_room_03_02, images.neihu_room_03_03, images.neihu_room_03_04],
      itemArr: [
        'Queen size床',
        '隱私拉門',
        '浴室乾濕分離',
        '衛浴暖風機',
        'TOTO頂級衛浴設備(免治馬桶)',
        '貴妃椅',
        '奶瓶消毒鍋',
        '雙門冰箱',
        '保險箱',
      ],
    },
  ];
  const NeihuConnectionDatas = [
    {
      title: `電${'\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0'}話`,
      content: '(02) 2658 5999',
    },
    {
      title: `地${'\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0'}址`,
      content: '台北市內湖區瑞光路337號11-13樓(宏匯瑞光廣場)',
    },
    {
      title: '停車資訊',
      content: '本大樓地下室為嘟嘟房收費停車場',
    },
  ];

  const CustomImg = ({ width, imgSrc, style }) => (
    <img
      loading="lazy"
      src={imgSrc}
      style={{
        marginBottom: isMobile && 15,
        width: isMobile ? 'calc(100% - 20px)' : width,
        marginLeft: isMobile ? 25 : 15,
        height: 'fit-content',
        objectFit: isMobile ? 'cover' : '',
        borderRadius: 6,
        ...style,
      }}
    />
  );

  const CustomSpan = ({ title, content, key }) => (
    <div style={{ display: 'flex' }} key={key}>
      <span style={{ fontSize: 16, whiteSpace: 'nowrap', color: '#5E5E5E', fontWeight: 400 }}>{title}</span>
      <span style={{ marginLeft: 10, marginBottom: 15, fontSize: 16, color: '#5E5E5E', fontWeight: 400 }}>{content}</span>
    </div>
  );

  return (
    <TopScrollContainer
      breadcrumb={[{ title: '首頁', to: '/' }, { title: '內湖' }]}
      titleBackground={images.top_05_a}
      background={images.background_05_a}
      title={isMobile ? '內湖' : '國泰內湖產後護理之家'}
      titleTab={['公共區域', '房型介紹', '聯絡方式']}
      // titleBtn={tabSelect == 0 ? ['遊戲區', '嬰兒室', '媽媽空間', '接待大廳', '諮詢室'] : null}
      handleChange={(index) => produce((draft) => void (draft.tabSelect = index))}
      // isShowBtnMobile={true}
      // isShowLineMobile={true}
    >
      <MetaTags>
        <title>內湖會館房型與服務介紹｜內湖區產後護理之家推薦｜國泰產後護理之家</title>
        <meta name="description" content="國泰內湖產後護理之家，位於台北市內湖區(內科)，專業醫護陣容定期巡診，24小時全護理師照護，專屬營養師協助體態恢復，坐月子明星規格尊榮待遇，立即預約！" />
        <meta
          name="keywords"
          content="國泰產後護理之家,國泰月子中心.國泰產後,國泰內湖產後護理之家,國泰產後護理之家 內湖,產後護理之家,月子中心,內湖月子中心,內湖國泰產後護理之家,台北月子中心,月子中心推薦"
        />
      </MetaTags>
      {tabSelect == 0 && (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {_.map(NeihuPublicDatas, (data, index) =>  (index > 0) && (
              <div key={index}>
                {index == 0 && !isMobile && (
                  <div style={{ textAlign: 'center' }}>
                    <span style={{ marginRight: 15 }}>嚴格把關 用心守護</span>
                    <span style={{ fontWeight: 400 }}>採用符合法規之綠建材，更即時管控館內空氣品質、環境溫溼度，守護媽咪、寶寶健康</span>
                  </div>
                )}
                <Row
                  id={`item${index}`}
                  className={index == 0 && isMobile ? '' : isMobile ? 'mt-4' : 'mt-4 mb-4'}
                  style={{ width: '100%', flex: 1, flexDirection: index % 2 == 1 ? 'row-reverse' : 'row' }}
                >
                  {
                    <img
                      loading="lazy"
                      src={data.image}
                      style={{
                        width: index == 1 || index == 2 ? (isMobile ? '100%' : '30%') : isMobile ? 'calc(100% - 20px)' : '65%',
                        height: isMobile ? 260 : 'fit-content',
                        marginLeft: isMobile ? 25 : 15,
                        objectFit: isMobile ? 'cover' : '',
                        borderRadius: 6,
                        marginBottom: isMobile ? 20 : '',
                      }}
                    />
                  }
                  {index == 1 && (
                    <img
                      loading="lazy"
                      src={data?.image2}
                      style={{
                        width: isMobile ? '100%' : '30%',
                        height: 'fit-content',
                        marginLeft: isMobile ? 25 : 15,
                        marginBottom: isMobile ? 20 : '',
                      }}
                    />
                  )}
                  <Col className="ml-2">
                    <h4>{data.title}</h4>
                    <ul style={{ marginLeft: -15 }}>
                      {_.map(data.items, (item, index) => (
                        <li
                          key={index}
                          style={{
                            fontSize: 16,
                            color: '#5E5E5E',
                            textAlign: 'justify',
                            fontWeight: 400,
                            marginBottom: 15,
                            listStyleImage: `url(${images.tree_icon})`,
                          }}
                        >
                          {item}
                        </li>
                      ))}
                    </ul>
                  </Col>
                </Row>

                {index == 2 && (
                  <div></div>
                )}
                {index != NeihuPublicDatas.length - 1 && (
                  <Row
                    style={{
                      marginTop: isMobile ? '' : 19,
                      background: '#C5C1BA',
                      height: 1,
                      marginRight: 0,
                    }}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      )}
      {tabSelect == 1 && (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {_.map(NeihuRoomDatas, (data, index) => (
              <div key={index}>
                <Row id={`item${index}`} className={index == 0 && isMobile ? '' : isMobile ? "mt-4" : "my-4"} style={{ width: '100%', flex: 1 }}>
                  <CustomImg imgSrc={data.image} width={'65%'} />
                  <Col className="ml-2" style={{ alignSelf: 'center' }}>
                    <h4>{data.title}</h4>

                    <div style={{ display: 'flex' }}>
                      <img className="mr-1 lazy" src={images.square_feet} height={22} width={22} />
                      <span style={{ display: 'flex', marginBottom: 10 }}>{data?.plain}</span>
                    </div>

                    <span
                      style={{
                        fontSize: 16,
                        color: '#5E5E5E',
                        textAlign: 'justify',
                        fontWeight: 400,
                      }}
                    >
                      {data?.content}
                    </span>

                    <div style={{ display: 'flex', marginTop: 15, width: '100%', justifyContent: isMobile ? 'center' : '' }}>
                      <Button
                        theme="success"
                        className="my-1"
                        style={{
                          maxHeight: 42,
                          width: 100,
                          fontSize: 16,
                          marginRight: 15,
                          backgroundColor: 'rgba(82,181,79,1)',
                        }}
                        onClick={() => produce((draft) => void (draft.detail = data))}
                      >
                        查看詳細
                      </Button>
                      <Button
                        theme="success"
                        className="my-1"
                        style={{
                          maxHeight: 42,
                          width: 100,
                          fontSize: 16,
                          backgroundColor: 'transparent',
                          color: 'rgba(82,181,79,1)',
                        }}
                        onClick={() => window.open(data?.link, '_blank')}
                      >
                        環景預覽
                      </Button>
                    </div>
                  </Col>
                </Row>

                {index != NeihuPublicDatas.length - 1 && (
                  <Row
                    style={{
                      marginTop: 19,
                      background: '#C5C1BA',
                      height: 1,
                      marginRight: 0,
                    }}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      )}
      {tabSelect == 2 && (
        <div
          style={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : '',
            marginTop: 15,
            paddingLeft: isMobile ? 10 : '',
            paddingRight: isMobile ? 10 : '',
          }}
        >
          <div style={{ width: isMobile ? '100%' : '85%', height: isMobile ? 250 : '50vh', position: 'relative' }}>
            <iframe
              src="https://www.google.com/maps/embed/v1/place?key=AIzaSyASdP6rUVNZ5zvDVUJy86BP_EToxexL_Kc&q=國泰內湖產後護理之家&language=zh-tw"
              width="100%"
              height="100%"
              frameBorder="0"
              style={{ border: 0 }}
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
            />
          </div>
          <div style={{ marginLeft: isMobile ? '' : 30, marginTop: 20, width: isMobile ? '100%' : '40%' }}>
            <h2 style={{ fontSize: 24, color: '#5E5E5E', fontWeight: 500 }}>聯絡方式</h2>
            {_.map(NeihuConnectionDatas, (item, index) => (
              <CustomSpan key={index} title={item.title} content={item.content} />
            ))}
          </div>
        </div>
      )}
      {detail && (
        <RoomPopUp
          isShowDialog={!!detail}
          overlayAction={() => {
            produce((draft) => void (draft.detail = null));
            push('/neihu-hall');
          }}
          detail={detail}
        />
      )}
    </TopScrollContainer>
  );
};

export default HeihuHall;
